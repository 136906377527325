import React, { useState } from 'react';
import Select from 'react-select';
import { countries } from 'countries-list';
import './EmailSignUp.css';

const EmailSignUp = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [name, setName] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const isEmailValid = validateEmail(email);
    const isPhoneValid = validatePhone(phone);

    if (isEmailValid && isPhoneValid) {
      console.log('Email submitted:', email);
      console.log('Phone number submitted:', phone);
      console.log('Country submitted:', selectedCountry ? selectedCountry.label : '');
      console.log('Name submitted:', name);
      setEmail('');
      setPhone('');
      setSelectedCountry(null);
      setIsValidEmail(true);
      setIsValidPhone(true);
      subscribeToMailchimp();
    } else {
      setIsValidEmail(isEmailValid);
      setIsValidPhone(isPhoneValid);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const subscribeToMailchimp = () => {
    const url = 'https://gmail.us21.list-manage.com/subscribe/post-json?u=cd88c2bfe77501151fff5efda&id=8ee7e53fcc&c=?';
    const formData = new FormData();
    formData.append('EMAIL', email);
    formData.append('PHONE', phone);
    formData.append('FNAME', name);
    formData.append('COUNTRY', selectedCountry ? selectedCountry.label : '');

    fetch(url, {
      method: 'POST',
      body: formData,
      mode: 'no-cors',
    })
      .then((response) => {
        console.log('Mailchimp response:', response);
        // Handle success or error responses from Mailchimp
      })
      .catch((error) => {
        console.error('Error subscribing to Mailchimp:', error);
        // Handle error subscribing to Mailchimp
      });
  };

  const countryOptions = Object.entries(countries).map(([countryCode, countryData]) => ({
    value: countryCode,
    label: countryData.name,
  }));

  return (
    <div className='signupform'>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <h1> SUBSCRIBE TO ANJELINA's NEWSLETTER</h1>
            <input
              type="text"
              placeholder="Enter your first name"
              value={name}
              onChange={handleNameChange}
              className='first-name-input'
            />
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              className={!isValidEmail ? 'invalid' : ''}
              
            />
            {!isValidEmail && <p className="error">Please enter a valid email address</p>}

            <input
              type="tel"
              placeholder="Enter your phone number"
              value={phone}
              onChange={handlePhoneChange}
              className={!isValidPhone ? 'invalid' : ''}
            />
            {!isValidPhone && <p className="error">Please enter a valid 10-digit phone number</p>}


            <Select
  value={selectedCountry}
  onChange={handleCountryChange}
  options={countryOptions}
  placeholder="Select a Country"
  className="custom-select"
  styles={{
    control: (provided, state) => ({
      ...provided,
      boxShadow: 'none',
      border: 'none',
      backgroundColor: 'white;',
      color: 'black',
      width: '100%',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#eeb0f8;' : 'inherit',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black', // Change the text color to red for the selected option when drop-down is closed
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
  }}
/>


          </div>
 

          <button type="submit">Subscribe</button>
        </form>
      </div>
    </div>
  );
};

export default EmailSignUp;

import React from 'react'
import Header from '../Components/Header';
import EmailSignUp from './EmailSignUp';

const Subscribe = () => {
  return (
    <div>
          <Header/>
      <EmailSignUp />
    </div>
  )
}

export default Subscribe



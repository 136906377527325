// About.js
import React from 'react';
import Header from '../Components/Header';
import LyricsComponent from './LyricsComponent';
import './About.css';

const lyricsData = [
  {
    title: 'The Meadow',
    content: [
      "You said that you had to be strong",
      "Well strength shouldn't feel so wrong",
      "You said that I fed your fire",
      "So tell me what I could have done to quench your desires",
      "I got so lost in your eyes",
      "I don't know if I'm looking into yours or mine",
      "Should I have sat around you killing time",
      "While you're dragging me",
      "Down, down, down, down",
      "",
      "One day you'll find why I left you behind",
      "Will you please forgive me",
      "Will you please forgive me",
      "My love won't heal",
      "No I won't make you feel whole",
      "But I would never let anyone drag you down to hell",
      "",
      "But what did I do to deserve your wrath",
      "We would be standing in the meadow",
      "You find lightning and you strike",
      "Start a wildfire that swallows up all life",
      "You made me feel like a bird",
      "Trapped in the hands of a sociopath",
      "Like is this what you wanted all this time",
      "To build a home among the ashes",
      "Among the shadows that never die",
      "So I can sit around killing time",
      "While you're dragging me",
      "Down, down, down, down",
      "",
      "One day you'll find why I left you behind",
      "Will you please forgive me",
      "Will you please forgive me",
      "My love won't heal",
      "No I won't make you feel whole",
      "But I would never let anyone drag you down to hell",
  ]
    ,
  },
  {
    title: 'Someone Else',
    content: [
      "When did it get so cold",
      "How I wish I knew",
      "Maybe I could make you warm",
      "Make the sun",
      "Glow inside you",
      "Till the darkness falls",
      "How I wish it was",
      "Someone else",
      "Wished you were",
      "The man I thought you were",
      "Still pretty bad",
      "But not like this",
      "You know it hurts",
      "To watch an angel fall",
      "But I guess we all have lost our way",
      "But when do all the lies",
      "Come crashing down",
      "How I wish I could",
      "Dig all the truth up",
      "Who made you think",
      "This was okay",
      "You can lie to yourself",
      "But you know damn well just",
      "How many lines you have crossed",
      "Every time you couldn't get a hold of yourself",
      "But you made me feel like it was my fault",
      "Every time that you had lost control",
      "But I want you to know",
      "It doesn't have to be so",
      "How I wish it was",
      "Someone Else",
      "You've really lost yourself",
      "In the darkness",
      "Wished you were",
      "The man I thought you were",
      "Still pretty bad",
      "But not like this",
      "You know it hurts",
      "To watch an angel fall",
      "But I guess we all have lost our way",
      "But did you really wake up every day",
      "Just choosing to be this way",
      "I just wanted you to say it wasn't your fault",
      "I just wanted someone else to blame",
  ]
  ,
  },
  {

   title: "On Your Way Home", 
   content: [
    "Dirt is treasure of the earth",
    "A diamond's worth is grain of sand",
    "If you can't see the morning light",
    "Doesn't mean outside is always night",
    "",
    "On your way home",
    "Will you remember me and cry",
    "",
    "Smashed his fist against the earth",
    "If this was love why do you hurt me so bad",
    "And in your eyes I still see light",
    "",
    "Burning skin under the sun",
    "If this was real where have you gone",
    "Will you remember me and cry",
    "Or choose to forget",
    "",
    "On your way home",
    "Will you remember me and cry",
    "",
    "Smashed his fist against the earth",
    "If this was love why do you hurt me so bad",
    "And in your eyes I still see light",
]
,
},
{
  title: "Paradise",
  content: [
    "What's the use of power if all it does is burn",
    "What's the use of love if it's just forgotten",
    "I was running down hill",
    "And my heart was hurt",
    "Oh, he picked me up",
    "Just to throw me in the dirt",
    "And there I go",
    "Drowning in lies",
    "For you and I",
    "There is no paradise",
    "",
    "We have forgotten who we are",
    "This ignorance leaves scars",
    "We would never if we remembered it",
    "And now I will go",
    "And I will find my way back home",
    "And the green ink on your arm",
    "Would have been a funny reminder of me",
    "If you remembered who the hell I am",
    "It's okay, I don't mind being a forgotten love",
    "Anyway",
    "",
    "You wrote me a letter",
    "Before it was too late",
    "You said one day we'll be in heaven",
    "Darling please have faith",
    "Then they took you to the wall",
    "And they shot you in the head",
    "You said it's joy and glory",
    "And the fame is worth the death",
    "You did it for the country",
    "You did it for the pride",
    "You did it for the emptiness that you had felt inside",
    "So what will it take for you",
    "To finally surrender",
    "In this war against yourself",
    "You're a losing general",
    "Here I go",
    "Drowning in lies",
    "For you and I",
    "Tommy there is no paradise",
    "",
    "We have forgotten who we are",
    "This ignorance leaves scars",
    "We would never if we remembered it",
    "And now I will go",
    "And I will find my way back home",
    "And the green ink on your arm",
    "Would have been a funny reminder of me",
    "If you remembered who the hell I am",
    "It's okay, I don't mind being a forgotten love",
    "Anyway",
]
,
}
,
{
  title: "Hear Me", 

  content: [
    "You can't trap a god inside the body of a man",
    "And think that he won't get out",
    "You can't hide his true self within him",
    "And ensure that he won't find out",
    "Heart to heart, soul to soul",
    "We were in the dark fighting control",
    "Behind the one way glass",
    "Who is watching us",
    "Who is watching us",
    "",
    "Here with me",
    "Here for me",
    "Here for me",
    "",
    "Hear me",
    "I am with you",
    "I am for you",
    "Lead me",
    "Through the darkness",
    "",
    "You can keep pushing it down",
    "But do you think that it will stay down",
    "If you try to hide the light inside the darkness of your heart",
    "It will burn",
    "Its way out",
    "Soul to soul, heart to heart",
    "In this world we've been torn apart",
    "But what we are cannot be torn",
    "It isn't here in physical form",
    "",
    "Hear me",
    "Here for me",
    "Here for me",
    "",
    "Hear me",
    "I am with you",
    "I am for you",
    "Lead me",
    "Through the darkness",
]
,

}
,
{
 title: "Hero",
 content: [
  "I don't even think about you sometimes",
  "You're not even ever on my mind",
  "When I pass by your town",
  "I don't even think about you're around",
  "I don't even cry about you some nights",
  "I don't even write about you sometimes",
  "When the darkness within you beats the light",
  "I don't even worry if you're alright",
  "Oh tell me, just tell me",
  "What was this all for",
  "",
  "Went off to another war",
  "Whatcha dying for",
  "Never enough just being my hero, hero",
  "Went off to another war",
  "Whatcha fighting for",
  "Never fought so hard for me love, me love",
  "",
  "Forget me in a hundred years",
  "I will cry a thousand tears",
  "When I remember this moment",
  "Keep me praying, I'll keep believing",
  "And you'll keep being the one who is leaving",
  "Always leaving me to die alone",
  "Oh tell me, just tell me",
  "What was this all for",
  "",
  "Went off to another war",
  "Whatcha dying for",
  "Never enough just being my hero, hero",
  "Went off to another war",
  "Whatcha fighting for",
  "Never fought so hard for me love, me love",
  "",
  "And if he can't find another war",
  "Out there to be won",
  "He will be another grave that I can always cry on",
  "Just to prove to himself that he is a real man",
  "In the eyes of everyone who won't remember him",
]
,

},
{
  title: "Dirt",
  content: [
    "Dirt underneath your nails",
    "Can't get clean",
    "You start to think",
    "It's a part of yourself",
    "Maybe I'm in hell",
    "Help",
    "Maybe I'm in hell",
    "Makes me feel like dirt",
    "Dirt",
    "Dirt",
    "Dirt",
    "You were running away",
    "I had begged you to stay",
    "You said your love is pure",
    "But I feel so insecure",
    "That it makes me feel like dirt",
    "Dirt on your face",
    "Can't get clean",
    "You believe",
    "It's your skin",
    "Maybe I'm in hell",
    "Help",
    "Maybe I'm in hell",
    "Don't you know your love",
    "Makes me feel like dirt",
    "Dirt",
    "Dirt",
    "Dirt",
    "You were running away",
    "I had begged you to stay",
    "You said your love is pure",
    "But I feel so insecure",
    "That it makes me feel like dirt",
]
,
  

}



];

const About = () => {
  return (
    <div className='content'>
      <Header />

      <h2> LYRICS </h2>
      <div className='lyrics-grid'>
        {lyricsData.map((lyric, index) => (
          <LyricsComponent key={index} title={lyric.title} content={lyric.content} />
        ))}
      </div>

      <h2> HERE I AM </h2>
      
      <div className='player'>  
      <div style={{ borderRadius: '12px', width: '100%', height: '100%', overflow: 'hidden' }}>
      <iframe
        src="https://open.spotify.com/embed/playlist/6GW9bs8Te6PaemKvynSrL0?utm_source=generator"
        width="100%"
        height="352"
        frameBorder="0"
        allowFullScreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
        title="Spotify Playlist"
      />
    </div>

    

    </div>
    </div>
  );
};

export default About;

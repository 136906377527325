// LyricsComponent.js
import React, { useState } from 'react';
import './LyricComponent.css';

const LyricsComponent = ({ title, content }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const renderLyrics = () => {
    const displayedLyrics = isCollapsed ? content.slice(0, 5) : content;

    return (
      <>
        <h1>{title}</h1>
        {displayedLyrics.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
        <button onClick={toggleCollapse}
style={{
  backgroundColor: 'rgb(255, 248, 248)',
  fontWeight: 'bold',
  width: '10px',
  border: 'none', // Add this line to remove the border
}}
           
           >
          {isCollapsed ? '... ' : '✗ '}
        </button>
      </>
    );
  };

  return (
    <div className='lyric'>
      {renderLyrics()}
    </div>
  );
};

export default LyricsComponent;

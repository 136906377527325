import image from './Assets/header.png';
import './App.css';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import Subscribe from './Components/Subscribe';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './Components/Footer';
import React, { useState } from 'react';




  const App = () => {

   return(

    <div className='app-container'>


    <Router>
      <Navbar />
      <Routes>
        <Route path="/Music" element={<About />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Subscribe" element={<Subscribe />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  
    <Footer />
    </div>
  




   );



};

  


export default App; 
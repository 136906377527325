// Home.js
import React from 'react';
import image from '../Assets/header.png';
import Header from '../Components/Header'
import './Home.css';
import EmailSignUp from './EmailSignUp';
const Home = () => {
  return (
    <div className='home-container'>
      
     <Header/>

      <div className='about'>
        <h1>SONGWRITER / ARTIST / COMPOSER </h1>
        <p>
         When I first started writing music I only had my voice, my heart, and my guitar. 
        </p> 
        <p>
        I crafted melodies about the ghosts that haunted me until their echoes faded into the past. 
        </p>
        <p>
        I composed symphonies in moments of anger, the music a cathartic release that dissolved the rage into silence.
        </p>
        <p>
        Lyrics spilled from my heart, capturing the essence of sadness, until those poignant notes transformed into echoes of silence.
        </p>
        <p>
        I was writing music about love, but love only deepened. †
        </p>

      </div>



    </div>
  );
};

export default Home;

import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import './Navbar.css';
import Home from './Home.js';
import About from './About.js';
import Contact from './Contact.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify } from '@fortawesome/free-brands-svg-icons';
import { faApple, faYoutube } from '@fortawesome/free-brands-svg-icons';


let spotify_link = "https://open.spotify.com/artist/0loY09M3DgO9TNXiumn7hc?si=8-VeKw9YSAKcmFbM-w8ddA";
let apple_link = "https://music.apple.com/us/artist/anjelina/1659882758";
let youtbe_link = "https://music.youtube.com/channel/UCFIUsjn_IFh-hYcSQ_BC2HA?feature=share";

const Navbar = () => (
    <div className='nav-container'>
  
      <nav className='navbar'>

        <div className='Navigation-links'> 
        <ul>
        <li><Link to="/">Home</Link></li>
          <li><Link to="/Music">Music</Link></li>
          <li><Link to="/contact">Contact</Link></li>
          <li><Link to="/subscribe">Subscribe</Link></li>
        </ul>
        </div>
         

        <div className='Title'>

         ANJELINA

        </div>


         <div className='icon-links'> 
          <ul>

          <li>
            <a href= {spotify_link} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faSpotify} />
            </a>
          </li>
          <li>
            <a href= {apple_link} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faApple} />
            </a>
          </li>
          <li>
            <a href= {youtbe_link} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </li>

          </ul>
 

         </div>

      </nav>
    </div>
  );
  
  export default Navbar;
  
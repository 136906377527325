import React, { useState } from 'react';
import './Footer.css'; // Import your CSS file

const Footer = () => {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const submit = "Subscribe"

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const isEmailValid = validateEmail(email);

    if (isEmailValid) {
      console.log('Email submitted:', email);
      setEmail('');
      setIsValidEmail(true);
      subscribeToMailchimp();
    } else {
      setIsValidEmail(isEmailValid);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const subscribeToMailchimp = () => {
    const url = 'https://gmail.us21.list-manage.com/subscribe/post-json?u=cd88c2bfe77501151fff5efda&id=8ee7e53fcc&c=?';
    const formData = new FormData();
    formData.append('EMAIL', email);

    fetch(url, {
      method: 'POST',
      body: formData,
      mode: 'no-cors',
    })
      .then((response) => {
        console.log('Mailchimp response:', response);
        // Handle success or error responses from Mailchimp
      })
      .catch((error) => {
        console.error('Error subscribing to Mailchimp:', error);
        // Handle error subscribing to Mailchimp
      });
  };

  return (
    <div className="footer-container">
      <div className="copyright">&copy; ANGELINA</div>

      <form onSubmit={handleSubmit} className="subscribe-form">
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={handleEmailChange}
          className={!isValidEmail ? 'invalid' : ''}
        />

        <button type="submit">  {submit} </button>
      </form>
    </div>
  );
};

export default Footer;

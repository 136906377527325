import React from 'react';
import './Header.css';
import image from '../Assets/header.png';

const Header = () => {
  return (
    <div>
  <img src={image} alt="My Image" className="centered-image" />




      <div className='header'></div>
      <div className="ticker">
        <div className="ticker__track">
          <div className="ticker__item">  V.IX.MCMXCIV  </div>
          <div className="ticker__item">  V.IX.MCMXCIV  </div>
          <div className="ticker__item">  V.IX.MCMXCIV  </div>
          <div className="ticker__item">  V.IX.MCMXCIV  </div>
          <div className="ticker__item">  V.IX.MCMXCIV  </div>
          <div className="ticker__item">  V.IX.MCMXCIV  </div>
          <div className="ticker__item">  V.IX.MCMXCIV  </div>
          <div className="ticker__item">  V.IX.MCMXCIV  </div>
        </div>
        <div className="ticker__track">
        <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
        </div>
        <div className="ticker__track">
        <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV. </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
        </div>
        <div className="ticker__track">
        <div className="ticker__item">    V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
          <div className="ticker__item">  V.IX.MCMXCIV.  </div>
        </div>
      </div>
    </div>
  )
}

export default Header
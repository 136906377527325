import React from 'react'
import Header from '../Components/Header';
import EmailSignUp from './EmailSignUp';
import ContactForm from './ContactForm';

const Contact = () => {
  return (
    <div>
      <Header/>
      <ContactForm />
    </div>
  )
}

export default Contact
